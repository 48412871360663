<template>
    <b-overlay :show="loading" rounded="sm">
        <div
            style="min-height: calc(100vh - 20px)"
            class="fill-height d-flex align-items-center justify-content-center"
            tag="section"
        >
            <b-row class="justify-content-center no-gutters" v-if="!loading">
                <b-col lg="9" md="9" class="d-flex align-items-center">
                    <b-card no-body>
                        <div class="d-flex align-items-center">
                            <div class="p-5">
                                <div class="text-center" v-if="success">
                                    <i class="display-2 text-primary mdi mdi-check-circle"></i>
                                    <h2 class="display-5 text-primary fw-medium">SUCCESS</h2>
                                    <p class="mt-4 text-primary font-weight-normal">
                                        Successfully verified mail - Your account is now fully activated, granting you
                                        access to all features and benefits.
                                    </p>
                                </div>
                                <div class="text-center" v-else>
                                    <i class="display-2 text-danger mdi mdi-alert-circle-outline"></i>
                                    <h2 class="display-5 text-danger fw-medium">Error</h2>
                                    <p class="mt-4 text-danger font-weight-normal">
                                        Error: Unable to verify email - Your account activation could not be completed
                                        at this time.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-overlay>
</template>

<script>
export default {
    name: 'EmailVerification',
    data() {
        return {
            loading: true,
            success: null,
        };
    },
    methods: {
        async verifyEmail() {
            this.loading = true;
            let data = {
                id: this.$route.params.id,
                firstName: null,
                lastName: null,
                email: '',
                emailNumber: this.$route.query.EmailNumber,
            };
            const res = await this.$store.dispatch('customer/update_statusEmailVerification', data);
            this.success = res?.success ?? false;
            this.loading = false;
        },
    },
    created() {
        this.verifyEmail();
    },
};
</script>
